import React from 'react';
import logo from './logo.svg';
import './App.css';


const { REACT_APP_ENVIRONMENT } = process.env;

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Feature 11
        </p>
        <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          {REACT_APP_ENVIRONMENT} Environment
        </a>
      </header>
    </div>
  );
}

export default App;
